import React, { useEffect } from "react"
import { CustomerCtx, CartCtx, useContextState } from "@components/contexted"
import { GitHubApi } from "@api"
import { Link } from "gatsby"

import SuccessSVG from "./icons/success.svg"
import { summary } from "./styles/summary.module.scss"

import { WPApi } from "@api"

const PurchaseCompleted = ({ orderId }) => {
  useEffect(() => {
    WPApi.deleteToken()
      .then((data) => {
        console.log(data)
        localStorage.removeItem("token")
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <div className={summary}>
      <img src={SuccessSVG} alt="success"></img>
      <h1>Dziękujemy za udany zakup!</h1>
      <p>
        Teraz już możesz wrócić do czytania wartościowych treści na moim blogu
      </p>
      <Link to={"/blog"}>
        <button>Do bloga</button>
      </Link>
    </div>
  )
}
export default PurchaseCompleted
