// extracted by mini-css-extract-plugin
export const heading = "product-module--heading--3liG8";
export const description__navy = "product-module--description__navy--2QKKB";
export const description__white = "product-module--description__white--lxR6P";
export const blocked = "product-module--blocked--2tT5X";
export const loader = "product-module--loader--1EipO";
export const offer = "product-module--offer--3KR-n";
export const offer__modal = "product-module--offer__modal--3sjcO";
export const offer__modal__wrapper = "product-module--offer__modal__wrapper--2rmZm";
export const offer__modal__wrapper__description = "product-module--offer__modal__wrapper__description--3wegO";
export const offer__modal__wrapper__description__coupon = "product-module--offer__modal__wrapper__description__coupon--euYVw";
export const offer__modal__wrapper__description__tags = "product-module--offer__modal__wrapper__description__tags--2RM0K";
export const offer__modal__bought__wrapper = "product-module--offer__modal__bought__wrapper--3wvCR";
export const offer__modal__bought__wrapper__empty = "product-module--offer__modal__bought__wrapper__empty--1fWuH";
export const offer__modal__bought__wrapper__items = "product-module--offer__modal__bought__wrapper__items--3kcDI";
export const offer__modal__bought__wrapper__summary = "product-module--offer__modal__bought__wrapper__summary--3SvfP";
export const offer__modal__bought__wrapper__summary__button = "product-module--offer__modal__bought__wrapper__summary__button--3lvRv";
export const offer__modal__summary = "product-module--offer__modal__summary--2Qe8X";
export const offer__modal__buttons = "product-module--offer__modal__buttons--nJTp3";
export const offer__modal__buttons__button = "product-module--offer__modal__buttons__button--2R5gt";
export const offer__modal__buttons__simple = "product-module--offer__modal__buttons__simple--2z_28";
export const offer__modal__payment = "product-module--offer__modal__payment--386ZV";
export const offer__modal__payment__gitwrapper = "product-module--offer__modal__payment__gitwrapper--2h_lR";
export const offer__modal__payment__gitwrapper__gitlogin = "product-module--offer__modal__payment__gitwrapper__gitlogin--3jRJr";
export const offer__modal__payment__gitwrapper__gitlogin__label = "product-module--offer__modal__payment__gitwrapper__gitlogin__label--109LH";
export const offer__modal__payment__item = "product-module--offer__modal__payment__item--3z-tV";
export const cart__item = "product-module--cart__item--3z0vY";
export const cart__item__divider = "product-module--cart__item__divider--1P-Pu";