import React from "react"
import { GitHubApi } from "@api"
import GitHubLogin from "react-github-login"
import { CustomerCtx, useActions, useContextState } from "@components/contexted"
import {
  offer__modal__payment,
  offer__modal__payment__item,
  offer__modal__payment__gitwrapper,
  offer__modal__payment__gitwrapper__gitlogin,
  offer__modal__payment__gitwrapper__gitlogin__label,
} from "./styles/product.module.scss"
import { AnimatedButton } from "@components/shared"
import { Field } from "formik"

export const defaultValues = () => {
  const ctx = localStorage.getItem("CustomerCtx")
  return ctx
    ? JSON.parse(ctx)
    : {
        username: "",
        password: "",
        first_name: "",
        last_name: "",
        address_1: "",
        address_2: "",
        city: "",
        postcode: "",
        country: "Polska",
        email: "",
        phone: "",
        note: "",
      }
}

const onSuccessGithub = (changeValue, setFieldValue) => (data) => {
  GitHubApi.exchangeCode(data.code).then((data) => {
    if (data) {
      console.log("ppppp", data)
      changeValue({
        note: data.login,
      })
      setFieldValue("note", data.login)
    }
  })
}

const ContactForm = ({ formik: { errors, touched, setFieldValue } }) => {
  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const { note } = useContextState(CustomerCtx, ["note"])
  const onFailGithub = (data) => {
    console.log("onFailGithub", data)
  }
  return (
    <div className={offer__modal__payment}>
      <h3>Płatność</h3>
      <label htmlFor="data">Dane osobowe / Dane do wysyłki</label>
      <div>
        <div className={offer__modal__payment__item}>
          <label htmlFor="name">Twoje imie</label>
          <Field name="first_name" type="text" placeholder={"np. Jan"} />
          {errors.first_name && touched.first_name ? (
            <p>{errors.first_name}</p>
          ) : null}
        </div>
        <div className={offer__modal__payment__item}>
          <label htmlFor="surname">Twoje nazwisko</label>
          <Field name="last_name" type="text" placeholder={"np. Kowalski"} />
          {errors.last_name && touched.last_name ? (
            <p>{errors.last_name}</p>
          ) : null}
        </div>
        <div className={offer__modal__payment__item}>
          <label htmlFor="email">Twój adres email</label>
          <Field
            name="email"
            type="email"
            placeholder={"np. jan@kowalski.pl"}
          />
          {errors.email && touched.email ? <p>{errors.email}</p> : null}
        </div>
        {note === "" ? (
          <React.Fragment>
            <p>Zaloguj się do Githuba, aby potwierdzić że jesteś programistą</p>
            <div className={offer__modal__payment__gitwrapper}>
              <GitHubLogin
                clientId="485b41b89683709f9b11"
                onSuccess={onSuccessGithub(changeValue, setFieldValue)}
                onFailure={onFailGithub}
                buttonText={
                  <AnimatedButton
                    className={
                      offer__modal__payment__gitwrapper__gitlogin__label
                    }
                  >
                    <h5>Zaloguj się</h5>
                    <i className="fab fa-github"></i>
                  </AnimatedButton>
                }
                className={offer__modal__payment__gitwrapper__gitlogin}
                valid={true}
                redirectUri="https://https://gladiators-of-javascript.com//"
                // redirectUri="http://localhost:8000/"
              />
            </div>
          </React.Fragment>
        ) : (
          <div className={offer__modal__payment__item}>
            <label htmlFor="nick">Nick na Githubie</label>
            <input type="text" value={note} disabled={true} />
          </div>
        )}

        <div className={offer__modal__payment__item}>
          <label htmlFor="street">Ulica</label>
          <Field name="address_1" type="text" placeholder="np. Miejska 4" />
          {errors.address_1 && touched.address_1 ? (
            <p>{errors.address_1}</p>
          ) : null}
        </div>
        <div className={offer__modal__payment__item}>
          <label htmlFor="city">Miasto</label>
          <Field name="city" type="text" placeholder="np. Warszawa" />
          {errors.city && touched.city ? <p>{errors.city}</p> : null}
        </div>
        <div className={offer__modal__payment__item}>
          <label htmlFor="postcode">Kod pocztowy</label>
          <Field name="postcode" type="text" placeholder="np. 00-001" />
          {errors.postcode && touched.postcode ? (
            <p>{errors.postcode}</p>
          ) : null}
        </div>
        <div className={offer__modal__payment__item}>
          <label htmlFor="country">Kraj</label>
          <Field name="country" type="text" placeholder="np. Polska" />
          {errors.country && touched.country ? <p>{errors.country}</p> : null}
        </div>
      </div>
    </div>
  )
}

export default ContactForm
