// extracted by mini-css-extract-plugin
export const product__addtocart = "product-module--product__addtocart--3iy-H";
export const product__prices = "product-module--product__prices--2VHVx";
export const product__tags = "product-module--product__tags--2Hoc1";
export const product__tag = "product-module--product__tag--3DYmF";
export const product__variants = "product-module--product__variants--1lAvB";
export const product__price = "product-module--product__price--_MZ4j";
export const product__btns = "product-module--product__btns--fmZjF";
export const product__btn = "product-module--product__btn--flJE1";
export const product__btnAlt = "product-module--product__btnAlt--DveSW";
export const slider = "product-module--slider--u_VCv";