import React, { useContext, useEffect } from "react"
import FailedSVG from "./icons/failed.svg"
import { navigate } from "gatsby"
import { summary } from "./styles/summary.module.scss"
import { WPApi } from "@api"

const PurchaseFailed = ({ phase }) => {
  useEffect(() => {
    if (phase !== "idle") {
      WPApi.deleteToken()
        .then((data) => {
          console.log(data)
          localStorage.removeItem("token")
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }, [phase])

  return (
    <div className={summary}>
      <img src={FailedSVG} alt="failed"></img>
      <h1>Twoja płatność jest przetwarzana. Prosimy o cierpliwość.</h1>
      <p>W przypadku problemów prosimy o kontakt</p>

      <button
        onClick={() => {
          navigate("/")
        }}
      >
        Strona główna
      </button>
    </div>
  )
}

export default PurchaseFailed
