import { WPApi, PayUApi } from "@api"

const createOrder = (props) => {
  const { cart, initLoading, customer } = props
  let { shipping, items, coupon } = cart
  if (
    customer.first_name.length === 0 ||
    customer.last_name.length === 0 ||
    customer.email.length === 0
  ) {
    return Promise.reject({
      data: { message: "Niepoprawnie wypełniony formularz z danymi" },
    })
  }

  if (typeof shipping.total !== "number") {
    return Promise.reject({
      data: { message: "Brak wybranego typu wysyłki" },
    })
  }

  if (items.length === 0) {
    return Promise.reject({
      data: { message: "Brak produktów w koszyku" },
    })
  }

  initLoading()
  // fixes for formats
  shipping = { ...shipping, total: String(shipping.total) }
  if (coupon.length !== 0) {
    coupon = { coupon_lines: [{ code: coupon }] }
  }

  const _cart = items.map((el) => {
    const { product_id, variant_id, quantity } = el
    return variant_id
      ? {
          product_id,
          quantity,
          variation_id: variant_id,
        }
      : {
          product_id,
          quantity,
        }
  })

  return WPApi.registerAndGetToken(customer).then((data) => {
    const { token } = data.data
    if (token) {
      localStorage.setItem("token", token)
      return WPApi.postCreateOrder(customer, _cart, shipping, coupon, token)
    } else {
      return Promise.reject({
        data: {
          message:
            "Wystąpił błąd podczas składania zamówienia. Prosimy spróbować ponownie.",
        },
      })
    }
  })
}

const initPayment = (props) => {
  const { cart, initLoading } = props
  const token = localStorage.getItem("token")
  initLoading()
  return PayUApi.initPayment(cart.state.orderInApi.id, token)
}

export { createOrder, initPayment }
