import React, { useEffect, useContext, useState } from "react"
import { ResizedImage, AnimatedLink, AnimatedButton } from "@components/shared"
import {
  CustomerCtx,
  useActions,
  CartCtx,
  AppCtx,
  useContextState,
} from "@components/contexted"
import { WPApi } from "@api"
import { AddToCart } from "../Product/common"
import { withHtml } from "@components/logic"
import { createOrder, initPayment } from "../Checkout/common"
import {
  offer__modal__buttons,
  offer__modal__summary,
  offer__modal__buttons__button,
  offer__modal__buttons__simple,
  offer__modal__bought__wrapper__summary__button,
  offer__modal__wrapper,
  offer__modal__bought__wrapper,
  offer__modal__bought__wrapper__items,
  offer__modal__bought__wrapper__empty,
  offer__modal__bought__wrapper__summary,
  offer__modal__wrapper__description,
  offer__modal__wrapper__description__coupon,
  cart__item__divider,
} from "./styles/product.module.scss"
import ContactForm, { defaultValues } from "./ContactForm"
import CardSVG from "./icons/card.svg"
import LeftSVG from "./icons/leftarrow.svg"
import { Formik, Form } from "formik"
import * as Yup from "yup"
const Description = withHtml("span", "span")
import CartItem from "./CartItem"
import Tags from "./Tags"

const Prices = (props) => {
  const { state, sum, imBusy } = props
  const { val } = sum
  let price = val
  if (state.discountValue !== 0) {
    price = val - state.discountValue
  }
  return (
    <div>{imBusy ? <h3>Procesowanie...</h3> : <h3>Razem: {price} zł</h3>}</div>
  )
}

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .matches(/^[\s\p{L}]+$/u, {
      message: "Pole nie może zawierać cyfr i znaków specjalnych",
    })
    .required("Imię jest wymagane"),
  last_name: Yup.string()
    .matches(/^[\s\p{L}]+$/u, {
      message: "Pole nie może zawierać cyfr i znaków specjalnych",
    })
    .required("Nazwisko jest wymagane"),
  address_1: Yup.string().required("Adres jest wymagany"),
  city: Yup.string().required("Miasto jest wymagane"),
  postcode: Yup.string()
    .matches(/^[0-9]{2}-[0-9]{3}$/, {
      message: "Kod pocztowy jest nieprawidłowy",
    })
    .required("Kod pocztowy jest wymagany"),
  country: Yup.string().required("Kraj jest wymagany"),
  email: Yup.string()
    .email("Nieprawidłowy email")
    .required("Email jest wymagany"),
})

const ProductModal = (props) => {
  const [state, setState] = useState({
    coupons: [],
    discountValue: 0,
    imBusy: false,
  })
  const { note } = useContextState(CustomerCtx, ["note"])
  const { name, image, productTags, shortDescription, productId } = props
  const { coupon } = useContextState(CartCtx, ["coupon"])
  const { imBusy } = useContextState(AppCtx, ["imBusy"])
  const { setCoupon } = useActions(CartCtx, ["setCoupon"])
  const { items, sum } = useContextState(CartCtx, ["items", "sum"])
  const customer = useContext(CustomerCtx)
  const cart = useContext(CartCtx)
  const { changeValue } = useActions(CustomerCtx, ["changeValue"])
  const { loading, loaded } = useActions(AppCtx, ["loading", "loaded"])
  const { setOrderFromApi, onOrderApiError } = useActions(CartCtx, [
    "setOrderFromApi",
    "onOrderApiError",
  ])

  let itemsForDiscount = []
  if (items.length > 0) {
    items.forEach((el) => {
      itemsForDiscount.push({
        product_id: el.product_id,
        quantity: el.quantity,
      })
    })
  }

  const handleCoupon = (ev) => {
    let key = ev.keyCode || ev.which
    if (key === 13) {
      return ev.preventDefault()
    }
  }
  useEffect(() => {
    if (itemsForDiscount && coupon) {
      setState({
        ...state,
        imBusy: true,
      })
      WPApi.getDiscount(itemsForDiscount, coupon).then((data) => {
        setState({
          ...state,
          discountValue: data.discountValue,
          imBusy: false,
        })
      })
    } else {
      setState({
        ...state,
        discountValue: 0,
        imBusy: false,
      })
    }
  }, [items, coupon])
  return (
    <Formik
      initialValues={defaultValues()}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        changeValue({ ...values, note })

        if (!note) {
          return
        }

        if (!cart.state.orderInApi.id) {
          createOrder({
            cart: cart.state,
            customer: values,
            initLoading: loading,
          })
            .then((data) => {
              loaded()
              setOrderFromApi(data)
            })
            .catch(({ data }) => {
              loaded()
              onOrderApiError(data)
            })
        } else {
          initPayment({ cart, initLoading: loading })
            .then((payment) => {
              loaded()
              window.location.href = payment.redirectUri
            })
            .catch((payment) => {
              loaded()
              console.log("err", payment)
            })
        }
      }}
    >
      {(formik) => (
        <Form>
          <React.Fragment>
            <ContactForm formik={formik} />
            <div className={offer__modal__summary}>
              {state.discountValue !== 0 &&
              state.discountValue !== undefined ? (
                <h3>Razem: {(sum.val - state.discountValue).toFixed(2)} zł</h3>
              ) : (
                <h3>Razem: {sum.val.toFixed(2)} zł</h3>
              )}
            </div>
            <div className={offer__modal__buttons}>
              {state.paymentPhase ? (
                <AnimatedButton
                  className={offer__modal__buttons__button}
                  style={{ margin: "20px auto" }}
                  disabled={imBusy}
                  onClick={() => {
                    initPayment({ cart, initLoading: loading })
                      .then((payment) => {
                        loaded()
                        window.location.href = payment.redirectUri
                      })
                      .catch((err) => {
                        console.log(err)
                      })
                  }}
                >
                  {imBusy ? <p>Procesowanie...</p> : <p>Opłać zamówienie</p>}
                </AnimatedButton>
              ) : (
                <React.Fragment>
                  <AnimatedButton
                    className={offer__modal__buttons__button}
                    submit={true}
                  >
                    {imBusy ? (
                      <p>Procesowanie...</p>
                    ) : cart.state.orderInApi.id ? (
                      <p>Rozpocznij płatność</p>
                    ) : (
                      <p>Potwierdź zamówienie</p>
                    )}
                    <img src={CardSVG} alt="card"></img>
                  </AnimatedButton>
                </React.Fragment>
              )}
            </div>
          </React.Fragment>
        </Form>
      )}
    </Formik>
  )
}

export default ProductModal
